<template>
  <div class="h5 mt-1 flex-row align-items-center">
    <div class="container">
      <b-card>
        <div class="mt-2">Xin chào {{this.$route.query.name}}! Chúng tôi đã gởi đường dẫn kích hoạt tài khoản vào email <i>{{this.$route.query.email}}</i> của bạn.</div>
        <div class="mt-2">Bạn vui lòng đăng nhập tài khoản email và click vào đường dẫn để kích hoạt tài cho việc sử dụng. </div>
        <div class="mt-2">Chúng tôi trân trọng thông báo, bạn được sử dụng hệ thống miễn phí trong 100 ngày, tất cả thông tin liên quan hệ thống xin vui lòng liên hệ nhân viên để được hổ trợ chi tiết. </div>
        <div class="mt-2">Chúng tôi xin chân thành cảm ơn sự tin dùng của bạn.</div>
        <div class="mt-2">Trưởng nhóm phát triển hệ thống [sang cao].</div><br/><br/>
        <div class="mt-5"><i>Nếu bạn chưa nhận được email kích hoạt, click ở đây để hệ thống gởi lại email <a class="btn btn-primary" style="background: #87c7a0;" @click="sendEmail({email:this.$route.query.email,name:this.$route.query.name})"> gởi lại email kích hoạt </a></i></div>
      </b-card>
    </div>
  </div>
</template>

<style>
.h4 {
  line-height: 2em;
}
</style>

<script>
  import router from '../../router'

  export default {
    name: 'RegisterOk',
    components: {},
    data () {
      return {
        email:null,
        name:null
      }
    },
    computed: {
      user_login() {
        return this.$store.state.st_authentication.user
      }
    },
    mounted () {
      debugger
      if(this.user_login){
        this.redirect(this.user_login.company.business)
      }else{
        let email=this.$route.query.email

        if(!email) this.$router.push('/home')
        this.email=email
        this.name=this.$route.query.name
        this.sendEmail({email,name})
      }
    },
    methods: {
      async sendEmail(param){
        return await this.$store.dispatch(`st_user/register_ok_and_send_email`,param)
      },
      redirect(business){
        switch (business) {
          case 'system'://super admin
            router.push('/system')
            break
          case "cafe"://Quản lý kinh doanh cafe
            router.push('/cafe')
            break
          case 'vlxd'://Buôn bán vật liệu xây dựng
            router.push('/vlxd')
            break
          case 'dentist'://Quản lý phòng khám răng
            router.push('/dentist')
            break
          case 'pharmacy'://Quản lý nhà thuốc
            router.push('/pharmacy')
            break
          case 'karaoke'://Quản lý kinh doanh Karaoke
            router.push('/karaoke')
            break
          case 'food'://Quản lý kinh doanh quán ăn
            router.push('/food')
            break
          case 'pawn'://Quản lý kinh doanh cầm đồ
            router.push('/pawn')
            break
          case 'football'://Quản lý sân bóng đá mini
            router.push('/football')
            break
          case 'tole'://Quản lý kinh doanh Tole
            router.push('/tole')
            break
          case 'hotel'://Quản lý kinh doanh hotel
            router.push('/hotel')
            break
          case 'bida'://Quản lý kinh doanh bi da
            router.push('/bida')
            break
          case 'spa'://Quản lý trung tâm spa
            router.push('/spa')
            break
          case 'massage'://Quản lý trung tâm massage
            router.push('/massage')
            break
          case 'oto'://Quản lý trung tâm sửa chữa ô tô
            router.push('/oto')
            break
          case 'gym'://Quản lý Phòng tập gym
            router.push('/gym')
            break
        }
      }
    }
  }
</script>
